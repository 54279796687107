<template>
  <div class="marketing">
    <h1>营销活动</h1>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/28 11:01
 * @version 1.0
 * @description
 */
export default {
  name: 'Marketing'
}
</script>

<style scoped>

</style>
